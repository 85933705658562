export function GetJSONHederWithToken() {
    const token = localStorage.getItem("token");
    var header = {
        headers: {
            'Content-Type': 'application/json',
            "x-access-token": `${token}`,
            version: "1.0.0",
        }
    };
    return header;
}
export function GetMultipartHederWithToken() {
    const token = localStorage.getItem("token");
    var header = {
        headers: {
            'Content-Type': 'multipart/form-data',
            "x-access-token": `${token}`,
            version: "1.0.0",
        }
    };

    return header;
}
export const ExportToExcel = (arrayList) => {
    if (!arrayList || arrayList.length === 0) {
      return; // No data to export
    }
  
    // Helper function to flatten objects
    const flattenObject = (obj, parent = '', res = {}) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const propName = parent ? `${parent}_${key}` : key;
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            flattenObject(obj[key], propName, res);
          } else {
            res[propName] = obj[key];
          }
        }
      }
      return res;
    };
  
    // Flatten all objects in the array
    const flatArrayList = arrayList.map(item => flattenObject(item));
  
    // Create the HTML table structure as a string
    let table = `<table border="1"><tr>`;
    
    // Get the keys (column headers) from the first object
    const headers = Object.keys(flatArrayList[0]);
    headers.forEach(header => {
      table += `<th>${header}</th>`;
    });
    table += `</tr>`;
  
    // Add the data rows to the table
    flatArrayList.forEach((row) => {
      table += `<tr>`;
      headers.forEach(header => {
        const cellValue = row[header] !== undefined ? row[header] : ''; // Handle undefined values
        table += `<td>${cellValue}</td>`;
      });
      table += `</tr>`;
    });
    
    table += `</table>`;
  
    // Create a Blob with the correct MIME type for Excel
    const excelBlob = new Blob([table], {
      type: "application/vnd.ms-excel"
    });
  
    // Create a downloadable link for the Blob
    const link = document.createElement("a");
    const url = URL.createObjectURL(excelBlob);
    link.href = url;
    link.download = "data.xls"; // File name with .xls extension
  
    // Append the link to the document body and trigger the download
    document.body.appendChild(link);
    link.click();
  
    // Clean up by removing the link after download
    document.body.removeChild(link);
  };
  
  
  
  