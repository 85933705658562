import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../../components/Layout";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import logo from "../../../img/templogo.webp";
import { useSelector } from "react-redux";

function Reciept() {
  const { state } = useLocation();
  const [values, setValues] = useState(profile);
  const [image, setImage] = useState(profile.logo);
  const {profile} = useSelector((state) => state.profile);
  const receiptContent = React.createRef();

  const handlePrintReceipt = useReactToPrint({
    content: () => receiptContent.current,
    documentTitle: "Receipt",
    onBeforeGetContent: () => {
      // Optionally adjust the content before printing
    },
    style: `
          @media print {
            body {
              background-color: white !important;
              color: black !important;
            }

            .print-content {
              // display: block !important;
              background-color: white !important;
              background-image: none !important;
              color: black !important;
              border: none !important;
              width: 100% !important;
              height: 100vh !important;
              padding: 50px !important;
              padding-top:10px!important;
            }
            .print-content * {
              // display: block !important;
            }
          }
        `,
  });

  useEffect(() => {
    handlePrintReceipt();
  });

  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="p-4 print-content" ref={receiptContent}>
                <div
                  className="invoice-body justify-content-center"
                  id="receipt-content"
                >
                  <div className="invoice-table">
                  

                    <div className="row ">
                      <div className="col-3">
                        <div className="info-1">
                          <div className="invoice-logo-w">
                            <img alt="" src={image} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="col-9 d-flex align-items-center lh-1">
                        <div>
                          <div className="company-name">
                            <h2>{values?.companyName}</h2>
                            <p className="mb-1">Address: {values?.address} </p>
                           
                            <p className="mb-1"> Email: {values?.email}</p>
                          
                            <p className="mb-1">  Mobile No.: {values?.mobile_no}</p>
                          </div>
                          
                        </div>
                      </div>
                      <div className="col">
                        <div className="info-2 ">
                          <div className="company-address text-capitalize ">
                            <p>
                              <b className="fs-5">TO</b>
                            </p>
                            Name: {state?.rtoDetail?.name}<br/>
                            tel. {state?.rtoDetail?.mobile_no}<br/>
                            Address: {state?.rtoDetail?.address}
                           
                          </div>
                       
                        </div>
                      </div>
                      <div className="col d-flex justify-contentend align-items-end">
                       <div className="w-100 text-end mb-2"> Reciept Id: {state?.item?.receiptNumber}</div>
                      </div>
                    </div>

                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" className="fs-6">Description</th>
                          <th scope="col" className="fs-6">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>RTO work charges</td>
                          <td>
                            {" "}
                            <span>
                              <i className="fa fa-inr">
                                {" "}
                                {state?.rtoDetail?.work_fee}
                              </i>
                            </span>
                          </td>
                        </tr>
                     
                        <tr>
                          <td className="text-end">
                            {" "}
                            <b> Recieved Amount</b>
                          </td>
                          <td>
                            <b className="">
                              <span>
                                <i className="fa fa-inr">
                                  {" "}
                                  {state?.item?.amount}
                                </i>
                              </span>
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-end">
                            {" "}
                            <b> Due Amount:</b>
                          </td>
                          <td>
                            <b className="">
                              <span>
                                <i className="fa fa-inr">
                                  {" "}
                                  {state?.item?.remaining_amount}
                                </i>
                              </span>
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <hr />
                    <div className="row">
                      <div className="col">
                        <b>Description</b>
                      </div>
                      <div className="col text-end">
                        <b>Price</b>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col">
                        RTO Work Fees
                      </div>
                      <div className="col text-end">
                        
                         <span>
                         <i className="fa fa-inr">  {state?.rtoDetail?.work_fee}</i>
                        
                          </span> 
                        
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col">
                        Remaining Amount
                      </div>
                      <div className="col text-end">
                        
                        <span> <i className="fa fa-inr"> {parseInt(state?.item?.remaining_amount)}</i>
                        </span> 
                        
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col">
                       
                      </div>
                      <div className="col ">
                     
                        <b className="">
                          <span>
                        
                          <i className="fa fa-inr">  {state?.item?.amount}</i>
                         
                          </span>
                       
                        </b>
                      </div>
                    </div> */}
                    <hr />
                    {/* <div className="terms">
                      <div className="terms-header">Terms and Conditions</div>
                      <div className="terms-content">
                        Should be paid as soon as received, otherwise a 5%
                        penalty fee is applied
                      </div>
                    </div> */}
                    <div className="invoice-footer">
                      {/* <div className="invoice-logo">
                        <img alt="" src="img/logo.png" />
                        <span>Paper Inc</span>
                      </div> */}
                      {/* <div className="invoice-info">
                        <span>{values?.email}</span>
                        <span>{values?.mobile_no}</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button onClick={() => handlePrintReceipt()}>Print</button>
          </div>
        </div>
      </div>
      <style>
        {`
          @media print {
            body {
              background-color: white !important;
              color: black !important;
            }
            
            .print-content {
              // display: block !important;
              background-color: white !important;
              background-image: none !important;
              color: black !important;
              border: none !important;
              width: 100% !important;
              height: 100vh !important;
              padding: 40px !important;
               padding-top:10px!important;
              
            }
            .print-content * {
              // display: block !important;
            }
          }
        `}
      </style>
    </Layout>
  );
}

export default Reciept;
