import React, { useEffect, useRef, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import Layout from "../../components/Layout";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-browser-router";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import moment from "moment";
import { Button } from "react-bootstrap";
import BasicDetails from "../../components/Loan/BasicDetails";
import VehicleDetail from "../../components/Loan/VehicleDetail";
import LoanRequest from "../../components/Loan/LoanRequest";

import { getCompany } from "../../services/companies/company";
import LoanApproved from "../../components/Loan/LoanApproved";
import PaymentWithdrawal from "../../components/Loan/PaymentWithdrawal";
import { createTehsil } from "../../services/State/State";
import Layoutcopy from "../../components/Layout";
import { useSelector } from "react-redux";
import {addLoanLedger} from "../../services/Loan/loanLedger"
const initialState = {
  name: "",
  email: "",
  mobile_no: "",
  basic_remark: "",
  alternative_mobile_no: "",
  pincode: "",
  state: "Madhya Pradesh",
  district: "",
  district1: "",
  tehsil: "",
  tehsil1: "",
  address1: "",
  address2: "",
  maker_classification: "",
  sourcing: "",
  vehicle_type: "",
  vehicle_no: "",
  model: "",
  track_record_of_finance: "",
  distt_rto: "",
  finance_company_name: "",
  sce_name: "",
  area: "",
  land: "",
  previously_taken_loan_amount: "",
  occupation: "",
  nocStatus: "Yes",
  nocHoldPayment: 0,
  rtoHoldPayment: 0,
  insuranceHoldPayment: 0,
  RTO_Stamp_Fees: 0,
  vehicle_owner: "",
  vehicle_manufacturing_date: "",
  loan_type: "B.T.CASH",
  loan_request: "",
  sanction: 0,
  payment: 0,
  processing_fee: "",
  gst_fee: "",
  TO_fees: "",
  TO_AddressChange_Fees: "",
  CO_fees: "",
  DRC_Fees: "",
  HSRP_Fees: "",
  Open_BankAccount: "",
  Tax: "",
  otherExpences: "",
  document_fee: "",
  stamp_fee: "",
  valuation_fee: "",
  vehicalInsurance: "",
  status: "Pending Leed",
  totalDeduction: 0,
  totalRtoExpence: 0,
  applicantType: "Applicant",
  coApplicantName: "",
  coApplicantMobileNo: "",
  form_34: "",
  form_35: "",
  vehical_renewal: "",
  form_31: "",
};

function AddLoan() {
  const { role } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedLoanCompanies, setSelectedLoanCompanies] = useState([]);
  const [contactPerson, setContactPerson] = useState([]);
  const [values, setValues] = useState(initialState);
  const [activeTab, setActiveTab] = useState("firstStep");
  const [loanTaken, setLoanTaken] = useState("No");
  const [loanId, setLoanId] = useState(state?.item?._id);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [allCompanies, setAllCompanies] = useState("");
  const [ledgerEntry,setLedgerEntry]=useState([
    {
      loanId:loanId,
      date: new Date(),
      description: "",
      debit: 0,
      credit: 0,
      balance: 0,
    }
  ])

  const   handleTabClick = (tabName) => {
    if(tabName=="firstStep"){
      return true
    }
    
    if (tabName === "secondStep") {
      if (!values.name) {
        toast.error("Name is required");
        return false;
      }

      if (!values.mobile_no) {
        toast.error("Mobile Number is required");
        return false;
      } else {
        return true;
      }
    } else if (tabName === "thirdStep") {
      console.log(tabName);

      if (loanTaken != "No" && !values.distt_rto) {
        toast.error("Distt RTO is required");
        return false;
      }
      // }
      else {
        return true;
      }
    } else if (tabName === "fourthStep") {
      console.log(tabName);
      if (!values.loan_type) {
        toast.error("Please Select Loan Type");
        return false;
      }

      if (!values.loan_request) {
        toast.error("Loan Request Amount is required");
        return false;
      } else {
        return true;
      }
    } else if (tabName === "fifthStep") {
      console.log(tabName);

      if (!values.sanction) {
        toast.error("Sanction is required");
        return false;
      }
      if (!values.payment) {
        toast.error("payment Amount is required");
        return false;
      } else {
        return true;
      }
    } else if (tabName === "final") {
      console.log(tabName);
      return true;
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setValues({
        ...values,
        image: [...values.image, ...files],
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  // const handleRecords = (loanId, description, debit, credit, balance) => {
  //   setValues((prevState) => ({
  //     ...prevState,
  //     records: [...state.item.records,{ loanId:loanId,description: description,debit:debit,credit: credit,balance:balance }],
  //   }));
  // };
  // add update product
  const SaveLoan = (step, LoanCompanyApprovedId, status) => {
    if (step === "final") {
      if (!(values.payment == values.sanction - values.totalDeduction)) {
        toast.error(
          "Recieved Payment amount should be equal to Sanction amount minus total deduction"
        );
        return;
      }
    }
    console.log("in save loan");
    // if(values){
    if (step && !handleTabClick(step)) {
      console.log("no save loan");
      return;
    }
    let obj = {};
    const token = localStorage.getItem("token");

    let api = "addLoan";
    if (loanId) {
      api = "updateLoan";
    }

    if (values.form_34) {
      obj.form_34 = values.form_34;
    }
    if (values.form_35) {
      obj.form_35 = values.form_35;
    }

    if (values.vehical_renewal) {
      obj.vehical_renewal = values.vehical_renewal;
    }
    if (values.form_31) {
      obj.form_31 = values.form_31;
    }
    if (status) {
      obj.status = status;
    }
    obj.name = values.name;
    obj.totalDeduction = values.totalDeduction;
    obj.totalRtoExpence = values.totalRtoExpence;
    obj.email = values.email;
    obj.basic_remark = values.basic_remark;
    obj.mobile_no = values.mobile_no;
    obj.alternative_mobile_no = values.alternative_mobile_no;
    obj.pincode = values.pincode;
    obj.state = values.state;
    obj.applicantType = values.applicantType;
    obj.coApplicantName=values.coApplicantName;
    obj.coApplicantMobileNo=values.coApplicantMobileNo;
    
    if (values.district != "Other" && values.district) {
      obj.district = values.district;
    } else {
      obj.district = values.district1;
    }

    if (values.tehsil == "Other") {
      obj.tehsil = values.tehsil1;
    } else {
      obj.tehsil = values.tehsil;
    }
    obj.vehicle_owner = values.vehicle_owner;
    if (values.vehicle_manufacturing_date) {
      obj.vehicle_manufacturing_date = values.vehicle_manufacturing_date;
    }
    obj.occupation = values.occupation;
    if (values.insuranceAmount) {
      obj.insuranceAmount = values.insuranceAmount;
    }
    if (values.insuranceCompany) {
      obj.insuranceCompany = values.insuranceCompany;
    }

    if (values.TO_fees) {
      obj.TO_fees = values.TO_fees;
    }

    if (values.TO_AddressChange_Fees) {
      obj.TO_AddressChange_Fees = values.TO_AddressChange_Fees;
    }

    if (values.CO_fees) {
      obj.CO_fees = values.CO_fees;
    }

    if (values.HSRP_Fees) {
      obj.HSRP_Fees = values.HSRP_Fees;
    }

    if (values.DRC_Fees) {
      obj.DRC_Fees = values.DRC_Fees;
    }

    if (values.otherExpences) {
      obj.otherExpences = values.otherExpences;
    }

    if (values.vehicalInsurance) {
      obj.vehicalInsurance = values.vehicalInsurance;
    }

    if (values.Tax) {
      obj.Tax = values.Tax;
    }

    if (values.Open_BankAccount) {
      obj.Open_BankAccount = values.Open_BankAccount;
    }

    obj.address1 = values.address1;
    obj.address2 = values.address2;
    obj.maker_classification = values.maker_classification;

    if (values.sourcing) {
      obj.sourcing = values.sourcing;
    }
    obj.vehicle_no = values.vehicle_no;
    if (values.vehicle_type) {
      obj.vehicle_type = values.vehicle_type;
    }
    obj.model = values.model;
    obj.forcloser = values.forcloser;
    obj.track_record_of_finance = values.track_record_of_finance;
    obj.distt_rto = values.distt_rto;
    obj.finance_company_name = values.finance_company_name;
    obj.sce_name = values.sce_name;
    obj.loan_type = values.loan_type;
    obj.area = values.area;
    obj.land = values.land;
    obj.land = values.land;

    if (values.loan_request) {
      obj.loan_request = values.loan_request;
    }
    if (values.previously_taken_loan_amount) {
      obj.previously_taken_loan_amount = values.previously_taken_loan_amount;
    }
    if (values.sanction) {
      obj.sanction = values.sanction;
    }
    if (values.payment) {
      obj.payment = values.payment;
    }
    // if (values.records.length>0) {
    //   obj.records = values.records;
    // }
    if (state?.approvedHistoryId) {
      obj.approvedHistoryId = state?.approvedHistoryId;
    } else if (LoanCompanyApprovedId) {
      obj.approvedHistoryId = LoanCompanyApprovedId;
    }
    if (values.loan_amount) {
      obj.loan_amount = values.loan_amount;
    }
    obj.nocStatus = values.nocStatus;
    if (selectedLoanCompanies?.length > 0) {
      for (let i = 0; i < selectedLoanCompanies.length; i++) {
        obj.companyIds = selectedLoanCompanies[i]._id;
      }
    } else {
      obj.companyIds = [];
    }
    if (values.processing_fee) {
      obj.processing_fee = values.processing_fee;
    }
    if (values.RTO_Stamp_Fees) {
      obj.RTO_Stamp_Fees = values.RTO_Stamp_Fees;
    }
    if (values.gst_fee) {
      obj.gst_fee = values.gst_fee;
    }
    if (values.document_fee) {
      obj.document_fee = values.document_fee;
    }
    if (values.stamp_fee) {
      obj.stamp_fee = values.stamp_fee;
    }
    if (values.valuation_fee) {
      obj.valuation_fee = values.valuation_fee;
    }

    if (loanId) {
      obj.id = loanId;
    }
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/${api}`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      }).then((response) => {
        if (response.data.success) {
          setLoading(false);
          if (step === "final") {
            if (values.sourcing) {
              SaveCommissionSalary(values.sanction, values.sourcing);
            }
            navigate("/loan-listing");
          }

          LoanByLoanId(response.data.data._id);
          setLoanId(response.data.data._id);
          toast.success(response.data.message);
          if (step == "secondStep") {
            AddTehsils();
          }
          if (step !== "fourthStep") {
            setActiveTab(step);
          }
          if(step !== "secondStep" && role === '1'){
            navigate("/loan-listing");
          }
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });

    // }
  };

  // generate commission based salary

  const SaveCommissionSalary = (sanction, sourcing) => {
    const token = localStorage.getItem("token");
    let obj = {
      sanction: sanction,
      sourcing: sourcing,
      loanId: loanId,
    };
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/addCommissionSalary`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const LoanByLoanId = (loanId, permission, step) => {
    const token = localStorage.getItem("token");
    let obj = {
      loanId: loanId,
    };
    if (values.sourcing) {
      obj.sourcing = values.sourcing;
    }
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/getLoanByLoanId`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setValues({ ...response.data.data[0] });
          setSelectedCompanies(response.data.data[0].Companies);
          setSelectedLoanCompanies(response.data.data[0].Companies);
          setContactPerson(response.data.data[0].ContactPerson);
          setLoanId(response.data.data[0]._id);
          if (permission) {
            SaveLoan("fourthStep");
          }
        } else {
          setLoading(false);
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };


  // state
  const AddTehsils = async () => {
    setLoading(true);
    let obj = {
      tehsil: values.tehsil1,
      district: values.district,
    };
    const data = await createTehsil(obj);
    if (data.data.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };


  // const AddLoanLedger=async(obj)=>{
  //   const data=await addLoanLedger(obj);
  //   if (data.data.success) {
  //     setLoading(false);
  //   } else {
  //     setLoading(false);
  //   }
  // }
  const getCompanies = async () => {
    const data = await getCompany();
    if (data?.data?.success) {
      setAllCompanies(data?.data?.data);
    } else {
      setAllCompanies([]);
    }
  };
  // payment withdrawal

  useEffect(() => {
    if (loanId && values.finance_company_name) {
      setLoanTaken("Yes");
    }
    if (state) {
      if (state?.LoanStatus == "Agree Leed") {
        console.log(state);
        setActiveTab("fourthStep");
        setLoanId(state.loanId);
        LoanByLoanId(state.loanId, true);
        return;
      } else {
        setValues({ ...state?.item });
        setSelectedCompanies(state?.item?.Companies);
        setSelectedLoanCompanies(state?.item.Companies);
        setContactPerson(state?.item?.ContactPerson);
        setLoanId(state?.item._id);
        console.log(state);
      }
    }
    getCompanies();
  }, []);
  useEffect(() => {
    const totalRtoExpence =
      (parseInt(values?.TO_fees) || 0) +
      (parseInt(values?.TO_AddressChange_Fees) || 0) +
      (parseInt(values?.CO_fees) || 0) +
      (parseInt(values?.DRC_Fees) || 0) +
      (parseInt(values?.HSRP_Fees) || 0) +
      (parseInt(values?.RTO_Stamp_Fees) || 0) +
      (parseInt(values?.otherExpences) || 0) +
      (parseInt(values?.Tax) || 0) +
      (parseInt(values?.Open_BankAccount) || 0) +
      (parseInt(values?.vehicalInsurance) || 0) +
      (parseInt(values?.form_34) || 0) +
      (parseInt(values?.form_35) || 0) +
      (parseInt(values?.vehical_renewal) || 0) +
      (parseInt(values?.form_31) || 0);

    setValues((prevValues) => ({
      ...prevValues,
      totalRtoExpence,
    }));
  }, [
    values?.TO_fees,
    values?.TO_AddressChange_Fees,
    values?.CO_fees,
    values?.DRC_Fees,
    values?.HSRP_Fees,
    values?.RTO_Stamp_Fees,
    values?.Tax,
    values?.Open_BankAccount,
    values?.otherExpences,
    values?.vehicalInsurance,
    values?.form_31,
    values?.form_34,
    values?.form_35,
    values?.vehical_renewal,
  ]);

  useEffect(() => {
    let totalDeduction =
      (parseInt(values?.processing_fee) || 0) +
      (parseInt(values?.gst_fee) || 0) +
      (parseInt(values?.insuranceAmount) || 0) +
      (parseInt(values?.document_fee) || 0) +
      (parseInt(values?.stamp_fee) || 0) +
      (parseInt(values?.valuation_fee) || 0);

    setValues((prevValues) => ({
      ...prevValues,
      totalDeduction,
    }));
  }, [
    values?.processing_fee,
    values?.gst_fee,
    values?.insuranceAmount,
    values?.document_fee,
    values?.stamp_fee,
    values?.valuation_fee,
  ]);

  useEffect(() => {
    if (values?.processing_fee) {
      // Calculate GST as 18% of the processing fee
      let gst_fee = (18 / 100) * parseInt(values?.processing_fee, 10);

      setValues((prevValues) => ({
        ...prevValues,
        gst_fee,
      }));
    }
    console.log("role",role);
  }, [values?.processing_fee]);

  // useEffect(() => {
  //   console.log("record len",values.records?.length)
  //   if (values.records?.length === 0&&values.payment>0) {
  //     handleRecords(loanId, "Loan Received", 0, values.payment, values.payment);
  //   }
  //   if (values.records?.length === 1&&values.totalRtoExpence>0) {
  //     handleRecords(
  //       loanId,
  //       "RTO deduction",
  //       values.totalRtoExpence,
  //       0,
  //       parseInt(values.payment, 10) - parseInt(values.totalRtoExpence, 10)
  //     );
  //   }

  //   console.log("records",values.records)
  // }, [values.payment, values.totalRtoExpence]);
  
  return (
    <>
      <Layout>
        <div className="element-wrapper">
          <div className="element-box">
            <div>
              <div className="steps-w w-100">
                <div className="step-triggers">
                  <a
                    href="javascript:void(0)"
                    className={`step-trigger ${
                      activeTab == "firstStep" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("firstStep")}
                  >
                    Applicant Details
                  </a>
                  <a
                    href="javascript:void(0)"
                    className={`step-trigger ${
                      activeTab == "secondStep" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("secondStep")}
                  >
                    Vehical details
                  </a>
                  {
                    role!=2?"":<>
                     <a
                    href="javascript:void(0)"
                    className={`step-trigger ${
                      activeTab == "thirdStep" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("thirdStep")}
                  >
                    Loan Request
                  </a>
                  <a
                    href="javascript:void(0)"
                    className={`step-trigger ${
                      activeTab == "fourthStep" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("fourthStep")}
                  >
                    Loan Approved
                  </a>

                  <a
                    href="javascript:void(0)"
                    className={`step-trigger ${
                      activeTab == "fifthStep" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("fifthStep")}
                  >
                    Payment Withdrawal
                  </a>
                    
</>
                  }
                 
                </div>
                <div className="step-contents">
                  <div
                    className={`step-content  ${
                      activeTab == "firstStep" ? "active" : ""
                    }`}
                    id="stepContent1"
                    style={{
                      display: activeTab == "firstStep" ? "block " : "none",
                    }}
                  >
                    <BasicDetails
                      handleInputChange={handleInputChange}
                      values={values}
                      SaveLoan={SaveLoan}
                      loanId={loanId}
                      loading={loading}
                    />
                  </div>
                  <div
                    className={`step-content ${
                      activeTab == "secondStep" ? "active" : ""
                    }`}
                    style={{
                      display: activeTab == "secondStep" ? "block " : "none",
                    }}
                  >
                    <VehicleDetail
                      handleInputChange={handleInputChange}
                      values={values}
                      setActiveTab={setActiveTab}
                      SaveLoan={SaveLoan}
                      loanId={loanId}
                      loading={loading}
                    />
                  </div>
                  <div
                    className={`step-content ${
                      activeTab == "thirdStep" ? "active" : ""
                    }`}
                    id="stepContent3"
                    style={{
                      display: activeTab == "thirdStep" ? "block" : "none",
                    }}
                  >
                    <LoanRequest
                      handleInputChange={handleInputChange}
                      values={values}
                      setActiveTab={setActiveTab}
                      SaveLoan={SaveLoan}
                      loanId={loanId}
                      loading={loading}
                      setSelectedLoanCompanies={setSelectedLoanCompanies}
                      selectedCompanies={selectedCompanies}
                      LoanByLoanId={LoanByLoanId}
                      contactPerson={contactPerson}
                      setAllCompanies={setAllCompanies}
                      allCompanies={allCompanies}
                    />
                  </div>

                  <div
                    className={`step-content ${
                      activeTab == "fourthStep" ? "active" : ""
                    }`}
                    id="stepContent3"
                    style={{
                      display: activeTab == "fourthStep" ? "block" : "none",
                    }}
                  >
                    <LoanApproved
                      handleInputChange={handleInputChange}
                      values={values}
                      setActiveTab={setActiveTab}
                      SaveLoan={SaveLoan}
                      loanId={loanId}
                      loading={loading}
                    />
                  </div>

                  <div
                    className={`step-content ${
                      activeTab == "fifthStep" ? "active" : ""
                    }`}
                    id="stepContent3"
                    style={{
                      display: activeTab == "fifthStep" ? "block" : "none",
                    }}
                  >
                    <PaymentWithdrawal
                      handleInputChange={handleInputChange}
                      values={values}
                      SaveLoan={SaveLoan}
                      setActiveTab={setActiveTab}
                      loanId={loanId}
                      setAllCompanies={setAllCompanies}
                      allCompanies={allCompanies}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AddLoan;
