import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { Link } from "react-browser-router";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

function ViewSalary() {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const currentDate = new Date();
const currentMonthName = monthNames[currentDate.getMonth()];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [month, setMonth] = useState(currentMonthName);

  const currentYear = new Date().getFullYear();
  const startYear = 2018;

  const { state } = useLocation();
  console.log("state", state);
  const [employees, setEmployees] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [employeeId, setEmployeeId] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const handleOnSearch = (string, results) => {
    console.log(string, results);
    getAllEmpSalary(string, results);
  };
  const handleOnSelect = (item) => {
    setSelectedEmployee(item);
  };
  const formatResult = (item) => {
    return (
      <span style={{ display: "block", textAlign: "left" }}>
        <p className="m-0">name: {item.name}</p>
        <p className="m-0">Mobile No.: {item.mobile_no}</p>
      </span>
    );
  };
  const getData = () => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `${BASE_URL}admin/getAllEmployees`,
        {},
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setEmployees(response.data.data);
          console.log("employees", response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const getAllEmpSalary = (string, results) => {
    console.log("string", string);
    console.log("results", results);

    const token = localStorage.getItem("token");
    let obj = {
      page: page,
      limit: 8,
    };
    if (year) {
      obj.year = year.toString();
    }
    if (month) {
      obj.month = month;
    }
    if (selectedEmployee) {
      obj.search = string;
    }
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/getAllEmployeesSalary`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setEmployees(response.data.data);
          console.log("salary", response.data.data);
        } else {
          setLoading(false);
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getAllEmpSalary();
  }, []);
  useEffect(() => {
    const yearsArray = [];
    for (let year = currentYear; year >= startYear; year--) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
  
  }, []);

  useEffect(() => {
    if (year && month) {
      getAllEmpSalary();
    }
  }, [year, month]);

  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="row">
                <div className="col-3 year-select-view-salary">
                  <select
                    className="form-control"
                    onChange={(e) => setYear(e.target.value)}
                    name="year"
                    value={year}
                  >
                    <option value="">--Select Year--</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-3 year-select-view-salary">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => setMonth(e.target.value)}
                      name="month"
                      value={month}>
                      <option value="">--Select a month--</option>
                      <option value="January">January</option>
                      <option value="Febuary">Febuary</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <ReactSearchAutocomplete
                    items={employees.employee}
                    onSearch={handleOnSearch}
                    // onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    // onFocus={handleOnFocus}
                    placeholder="Search By Name"
                    autoFocus
                    formatResult={formatResult}
                  />
                </div>
              </div>

              <h5 className="form-header">Office Employee Salary Listing</h5>
              <div className="table-responsive">
                {loading ? (
                  <div className="d-flex justify-content-center align-item-center">
                    <span className="spinner-border spinner-border-sm"></span>
                  </div>
                ) : (
                  <table className="table table-lightborder">
                    <thead>
                      <tr>
                        <th>Employee Id</th>
                        <th>Name</th>
                        <th>Mobile Number</th>
                        <th>Joining Date</th>

                        <th>Bonus</th>
                        <th>Leave Amount </th>

                        <th>Net Payable Salary</th>
                        <th>Status</th>
                        <th>Invoice</th>

                      </tr>
                    </thead>

                    <tbody>
                      {employees.length>=0&&employees.map((item, index) => (
                        item?.employee?.[0]&&(
                          <tr key={index}>
                          <td>{item?.employee?.[0]?.employeeId}</td>
                          <td>{item?.employee?.[0]?.name}</td>
                          <td>{item?.employee?.[0]?.mobile_no}</td>
                          <td>
                            {moment(item?.employee?.[0]?.joining_date).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                          <td>{item?.bonus}</td>
                          <td>{item?.leave_amount}</td>
                          <td>{item?.net_payable_salary}</td>
                          <td>{item?.status}</td>
                          <td ><i className="fa fa-print cursor-pointer"
                           onClick={ ()=>navigate("/salary-slip", { state: { item } })}
                           ></i></td>
                        </tr>
                        )
                          
                          ))
                        }
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default ViewSalary;
