import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../../components/Layout";
import moment from "moment";
import { ExportToExcel } from "../../../Utilities/CommonMethods";


function RTOAgentList() {
  const [agentList , setAgentList]=useState([]);
  const navigate= useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages]=useState(1)
const BASE_URL= process.env.REACT_APP_BASE_URL;

  const getData = () => {
    const token= localStorage.getItem('token');
    axios
      .post(`${BASE_URL}admin/getAllRTOAgent`, {}, {
        headers: {
          'x-access-token':`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
            setAgentList(response.data.data);
        }else{
          if(response.data.error_code==461){
            navigate("/login")
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const deleteRTO=(id)=>{
    const token= localStorage.getItem('token');
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    axios.delete(`${BASE_URL}admin/deleteRTOAgent/${id}`,{
      headers:{
        "x-access-token":`${token}`,
        "Content-Type":"Application/json",
        version:"1.0.0"
      }
    }).then((response)=>{
      if(response.data.success){
        toast.error(response.data.message);
        setAgentList([])
        getData();
      }
    }).catch((err)=>{
      console.log(err.message);
    })
  }

  const searchAgent = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_URL}admin/RTOAgentSearch`, {
        params: {
          search: searchTerm, // Search by name, mobile number, or vehicle number
          status: status      // Optional: Filter by status
        },
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          version: "1.0.0"
        }
      });
  
      if (response.data.success) {
        setAgentList(response.data.data); // Handle the response
      } else {
        setAgentList([]);
      }
    } catch (error) {
      console.error('Error fetching loans:', error);
    }
  };


  useEffect(()=>{
    if(searchTerm!=""||status!=""){
      searchAgent();
    }else{
      getData();
    }
  },[searchTerm,status])
  return (
    <Layout>
    <div className="content-i">
      <div className="content-box">
        <div className="element-wrapper">
          <div className="element-box">
            <h5 className="form-header">RTO  Agent Listing</h5>
            <div className="text-end ">
          <button className="btn btn-primary" onClick={()=>ExportToExcel(agentList)}>Export To CSV</button>
          </div>
          <div className="row d-flex justify-content-between my-4">
              <div className="col">
            
              <input
          type="text"
          placeholder="Search by name, mobile, or vehicle number"
          className="searchLoan"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
              </div>
              <div className="col-3 text-end">
              <select className="form-control searchSelect" name="status" value={status} onChange={(e)=>setStatus(e.target.value)}
                style={{ height:"45px", borderRadius:"0px" }}>
              <option value="" selected="true">Select Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
             </select>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-lightborder">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Mobile Number</th>
                    <th>State</th>
                    <th>District</th>
                    <th>Tehsil</th>
                    <th>Status</th>
                    {/* <th>price</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    agentList.length>0?agentList.map((item,index)=>(
                      <tr key={index}>
                   
                      <td>{item.name}</td>
                      <td>{item.mobile_no}</td>
                      <td>{item.state}</td>
                      <td>{item.district}</td>
                      <td>{item.tehsil}</td>


  
                      
                    
  
                      <td>{item.status}</td>
                      <td>
                        <button className="border-0 bg-white">
                          <i className="os-icon os-icon-pencil-2" 
                          onClick={() =>
                            navigate("/add-rto-agent", { state: { item } })
                          }
                          />
                        </button>
  
                        <button  className="text-danger border-0 bg-white ps-2" onClick={()=>deleteRTO(item._id)} >
                          <i className="os-icon os-icon-database-remove" />
                        </button>
                      </td>
                    </tr>
                    )):<div></div>
                  }
               
                </tbody>
              </table>
            </div>
          </div>
       
        </div>
      </div>
    </div>
    </Layout>
  );
}

export default RTOAgentList;
