import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import { Link } from "react-browser-router";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import BasicDetails from "../../components/Employee/BasicDetails";
import { createTehsil } from "../../services/State/State";
import { useSelector } from "react-redux";
import EmployeeDoc from "../../components/Employee/EmployeeDoc";
// const Cryptr = require("cryptr");
// const cryptr = new Cryptr("keyforencryption");
const initialState = {
  name: "",
  email: "",
  mobile_no: "",
  dob: "",
  pincode: "",
  joining_date: "",
  state: "Madhya Pradesh",
  district: "",
  tehsil: "",
  tehsil1: "",
  address: "",
  employee_type: "Office",
  commission: "",
  salary: "",
  account_holder_name: "",
  bank_name: "",
  branch_name: "",
  account_number: "",
  ifsc_code: "",
  identity_type: "Aadhar Card",
  identity_image: "",
  status: "Active",

  adminAccess: false,
  password: "",
  username: "",
};
function AddEmployee() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { role } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);

  const [file1, setFile1] = useState("");
  const [identity_Image, setIdentity_Image] = useState("");
  const fileInputRef = useRef(null);
  const [values, setValues] = useState(initialState);
  const [activeTab, setActiveTab] = useState("firstStep");
  const [empId, setEmpId] = useState("");
  const [loading, setLoading] = useState(false);

  const [employeeId, setEmployeeId] = useState("");

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const handleTabClick = (tabName) => {
    if (tabName === "secondStep") {
      if (values.employee_type == "") {
        toast.error("Employee type is required");
        return false;
      }
      if (!values.name) {
        toast.error("Name is required");
        return false;
      }
      if (!values.email) {
        toast.error("Email is required");
        return false;
      }
      if (!values.mobile_no) {
        toast.error("Mobile Number is required");
        return false;
      }
      if (!values.joining_date) {
        toast.error("Joining Date is required");
        return false;
      }
      if (!values.dob) {
        toast.error(" Date Of Birth is required");
        return false;
      }
      if (!values.state) {
        toast.error("State is required");
        return false;
      }
      if (!values.district) {
        toast.error("District is required");
        return false;
      }
      if (!values.address) {
        toast.error("Address is required");
        return false;
      } else {
        return true;
      }
    } else if (tabName === "thirdStep") {
      if (values.employee_type == "Office" && !values.salary) {
        toast.error("salary is required");
        return;
      }

      if (!values.account_holder_name) {
        toast.error("Account Holder Name  is required");
        return false;
      }
      if (!values.bank_name) {
        toast.error("Bank Name  is required");
        return false;
      }
      if (!values.branch_name) {
        toast.error("Branch Name  is required");
        return false;
      }
      if (!values.account_number) {
        toast.error("Account Number  is required");
        return false;
      }
      if (!values.ifsc_code) {
        toast.error("IFSC Code  is required");
        return false;
      } else {
        return true;
      }
    } else if (tabName === "final") {
      if (!values.identity_type) {
        toast.error("Please Select Identity Card Type");
        return false;
      } else {
        return true;
      }
    }
  };

  const AddTehsils = async () => {
    setLoading(true);
    let obj = {
      tehsil: values.tehsil1,
      district: values.district,
    };
    const data = await createTehsil(obj);
    if (data.data.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "identity_image") {
      setValues({
        ...values,
        identity_image: [...values.identity_image, ...files],
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const addImage = (e) => {
    setFile1(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      const newImageSrc = URL.createObjectURL(e.target.files[0]);
      setIdentity_Image((e) => [newImageSrc]);
    }
  };
  const SaveEmployee = (step) => {
    if (!handleTabClick(step)) {
      return;
    }
    const token = localStorage.getItem("token");
    const fd = new FormData();
    let api = "addEmployee";
    if (employeeId) {
      api = "updateEmployee";
    }
    fd.append("name", values.name);
    fd.append("employeeId", empId);
    fd.append("email", values.email);
    fd.append("mobile_no", values.mobile_no);
    fd.append("dob", values.dob);
    fd.append("pincode", values.pincode);
    fd.append("joining_date", values.joining_date);
    fd.append("state", values.state);
    if (values.adminAccess) {
      fd.append("adminAccess", values.adminAccess);
      fd.append("password", values.password);
      fd.append("username", values.username);
    }

    if (values.tehsil == "Other") {
      fd.append("tehsil", values.tehsil1);
    } else {
      fd.append("tehsil", values.tehsil);
    }
    fd.append("district", values.district);
    // fd.append("tehsil", values.tehsil);
    fd.append("address", values.address);
    fd.append("account_holder_name", values.account_holder_name);
    fd.append("bank_name", values.bank_name);
    fd.append("branch_name", values.branch_name);
    values.account_number != null &&
      fd.append("account_number", values.account_number);
    fd.append("ifsc_code", values.ifsc_code);
    fd.append("identity_type", values.identity_type);
    fd.append("status", values.status);
    fd.append("employee_type", values.employee_type);
    if (values.employee_type == "Field") {
      fd.append("commission", values.commission);
    } else {
      values.salary != null && fd.append("salary", values.salary);
    }

    file1 != null && fd.append("identity_image", file1[0]);

    if (employeeId) {
      fd.append("id", employeeId);
    }
    axios
      .post(`${BASE_URL}admin/${api}`, fd, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart/form-data",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          if (step === "final") {
            navigate("/employee-list");
          }
          if (step == "secondStep") {
            AddTehsils();
          }
          setEmployeeId(response.data.data._id);
          setValues(initialState);
          toast.success(response.data.message);
          setValues({ ...response.data.data });
          setActiveTab(step);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getEmployeebyId = () => {
    if (user._id) {
      axios
        .get(`${BASE_URL}admin/getemployeebyid/${user._id}`, {
          headers: {
            "x-access-token": localStorage.getItem("token"),
            version: "1.0.0",
          },
        })
        .then((response) => {
          if (response.data.success) {
            setValues(response.data.data);
            setEmployeeId(response.data.data._id);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  // keyword end
  const handleCheckboxChange = (event) => {
    setValues({ ...values, adminAccess: event.target.checked });
  };
  useEffect(() => {
    if (role != "1" && state) {
      setValues({ ...state.item });
      setEmployeeId(state.item._id);
      setEmpId(state.item.employeeId);
      setIdentity_Image(state.item.identity_image);
    } else {
      getEmployeebyId();
      // setEmployeeId(state?.item?._id);
    }
  }, []);

  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div>
                <div className="steps-w w-100">
                  <div className="step-triggers">
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${
                        activeTab == "firstStep" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("firstStep")}
                    >
                      Basic Information
                    </a>
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${
                        activeTab == "secondStep" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("secondStep")}
                    >
                      Salary details
                    </a>
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${
                        activeTab == "thirdStep" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("thirdStep")}
                    >
                      Documents
                    </a>
                    {
                      role!="1"&&
                      <a
                      href="javascript:void(0)"
                      className={`step-trigger ${
                        activeTab == "fourthStep" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("fourthStep")}
                    >
                      Login Details
                    </a>
                    }
                  
                  </div>
                  <div className="step-contents">
                    <div
                      className={`step-content  ${
                        activeTab == "firstStep" ? "active" : ""
                      }`}
                      id="stepContent1"
                      style={{
                        display: activeTab == "firstStep" ? "block " : "none",
                      }}
                    >
                      <BasicDetails
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        handleInputChange={handleInputChange}
                        values={values}
                        SaveEmployee={SaveEmployee}
                        setEmpId={setEmpId}
                        empId={empId}
                      />
                    </div>
                    <div
                      className={`step-content ${
                        activeTab == "secondStep" ? "active" : ""
                      }`}
                      style={{
                        display: activeTab == "secondStep" ? "block " : "none",
                      }}
                    >
                      <div className="row">
                        {values.employee_type == "Field" ? (
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">Commission (Ex: 10%)</label>
                              <input
                                className={`form-control`}
                                placeholder="Enter commission"
                                disabled={role !== "2"} // Disables the input if role is not "2"
                                readOnly={role !== "2"}
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                name="commission"
                                value={values.commission}
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label for="">Base Salary/Month</label>
                                <input
                                  className={`form-control `}
                                  disabled={role !== "2"} // Disables the input if role is not "2"
                                  readOnly={role !== "2"}
                                  placeholder="Enter salary"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="salary"
                                  value={values.salary}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <hr />
                      <h4 class="mb-4">Account details</h4>
                      <div className="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="">Account Holder Name:</label>
                            <input
                              class="form-control"
                              placeholder="Enter Account Holder Name"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="account_holder_name"
                              value={values.account_holder_name}
                            />
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="">Bank Name:</label>
                            <input
                              class="form-control"
                              placeholder="Enter Bank Name"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="bank_name"
                              value={values.bank_name}
                            />
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="">Branch Name:</label>
                            <input
                              class="form-control"
                              placeholder="Enter Branch Name"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="branch_name"
                              value={values.branch_name}
                            />
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="">Account Number:</label>
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Enter Account Number"
                              onChange={(e) => handleInputChange(e)}
                              name="account_number"
                              value={values.account_number}
                            />
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="">IFSC Code:</label>
                            <input
                              class="form-control"
                              placeholder="Enter IFSC Code"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              name="ifsc_code"
                              value={values.ifsc_code}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-buttons-w text-right">
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => setActiveTab("firstStep")}
                          >
                            Back
                          </button>
                        </span>
                        &nbsp;&nbsp;{" "}
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => SaveEmployee("thirdStep")}
                          >
                            Save
                          </button>
                        </span>
                        &nbsp;&nbsp;
                      </div>
                    </div>
                    <div
                      className={`step-content ${
                        activeTab == "thirdStep" ? "active" : ""
                      }`}
                      id="stepContent3"
                      style={{
                        display: activeTab == "thirdStep" ? "block" : "none",
                      }}
                    >
                      <EmployeeDoc
                        handleInputChange={handleInputChange}
                        values={values}
                        handleClick={handleClick}
                        fileInputRef={fileInputRef}
                        setActiveTab={setActiveTab}
                        SaveEmployee={SaveEmployee}
                        empId={employeeId}
                      />
                    </div>

                    <div
                      className={`step-content ${
                        activeTab == "fourthStep" ? "active" : ""
                      }`}
                      id="stepContent4"
                      style={{
                        display: activeTab == "fourthStep" ? "block" : "none",
                      }}
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <label>Login Permission</label>
                          <br />
                          <input
                            type="checkbox"
                            style={{ width: "60px", height: "36px" }}
                            onChange={(e) => handleCheckboxChange(e)}
                            name="adminAccess"
                            checked={values.adminAccess ? true : false}
                            value={values.adminAccess}
                          />
                        </div>
                        {values.adminAccess && (
                          <>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor>Username</label>
                                <input
                                  name="username"
                                  placeholder="Enter username"
                                  rows={4}
                                  className="form-control"
                                  onChange={(e) => handleInputChange(e)}
                                  value={values.username}
                                ></input>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor>Password</label>
                                <input
                                  name="password"
                                  placeholder="Enter password"
                                  rows={4}
                                  className="form-control"
                                  onChange={(e) => handleInputChange(e)}
                                  value={values.password}
                                ></input>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="form-buttons-w text-right">
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => setActiveTab("secondStep")}
                          >
                            Back
                          </button>
                        </span>
                        &nbsp;&nbsp;{" "}
                        <span className=" text-right">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => SaveEmployee("final")}
                          >
                            Save
                          </button>
                        </span>
                        &nbsp;&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddEmployee;
