import axios from "axios";
import React, { useEffect, useState } from "react";
import { useActionData, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./login.css";
import finaceImage from "../../img/Tractor-Loan-2.jpg";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../store/slice/authSlice";
import { setUser } from "../../store/slice/userSlice";

function Login() {
  const actionData = useActionData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const submit = (e) => {
    const obj = {
      username: username,
      password: password,
      type: type,
    };

    if (username.length > 100) {
      toast.error("please enter valid email address length ");
    } else {
      if (username) {
        setUsername(username);
      } else {
        toast.error("please enter valid email address");
      }
    }

    if (password.length > 6) {
      toast.error("password must be upto 6 digit ");
    } else if (password.length <= 3) {
      toast.error("password must be of minimum 4 digit");
    }
    if (!type) {
      toast.error("please select user type");
      return;
    } else if (type == "employee") {
      obj.role = "1";
    } else if (type == "admin") {
      obj.role = "2";
    }

    axios
      .post(`${BASE_URL}admin/adminLogin`, obj, {
        headers: {
          version: "1.0.0",
          "Content-type": "Application/json",
        },
      })
      .then(function (response) {
        if (response.data.success) {
          const { token, role, user } = response.data.data;
          console.log("user", user);
          dispatch(setCredentials({ token, role }));
          dispatch(setUser({ user }));
          navigate("/");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("actionData", actionData);
    if (actionData != null) {
      dispatch(setCredentials({ ...actionData }));
      dispatch(setUser({ ...actionData }));
    }
  }, [actionData]);
  return (
    <div className="all-wrapper menu-side with-pattern">
      <div className="auth-box-w">
        <div className="row">
          <div className="col-md-6 col-12 pt-3">
            <h4 className="auth-header">Login here</h4>
            <div className="loginForm">
              <div className="form-group">
                <label htmlFor>User Type</label>
                <select
                  className="form-control"
                  name="type"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                >
                  <option value="">Select User Type</option>
                  <option value="admin">Head Office</option>
                  <option value="employee">Staff</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor>Username</label>
                <input
                  className="form-control"
                  placeholder="Enter your username"
                  type="text"
                  name="email"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
              </div>
              <div className="form-group">
                <label htmlFor>Password</label>
                <input
                  className="form-control"
                  placeholder="Enter your password"
                  type="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
              <div className="buttons-w">
                <button className="btn btn-primary" onClick={() => submit()}>
                  Log me in
                </button>
                <div className="form-check-inline"></div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 col-12 p-0"
            style={{
              backgroundImage: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(${finaceImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <div
              className="text-white"
              style={{
                fontFamily: "monospace",
                fontSize: "50px",
                fontWeight: "700",
              }}
            >
              <p className="">Royal Financial </p>
              <p className="text-center">Services</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
