import React, { useEffect, useState } from "react";
import { Link } from "react-browser-router";
import { useNavigate } from "react-router-dom";
import logo from "../img/templogo.webp";
import axios from "axios";
import { useSelector } from "react-redux";

function MenuTop() {
  const [activeSubMenu, setActiveSubMenu] = useState("Active");
  const [profileDrop, setProfileDrop] = useState(false);
  const { role } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const LogOut = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const viewProfile = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}admin/viewCompanyProfile`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.data.logo);
        } else {
          if (response.data.error_code === 461) {
            localStorage.removeItem("token");
            navigate("/login");
          }
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    viewProfile();
  }, []);
  return (
    <>
      <div className="desktop-menu menu-top-w menu-activated-on-hover">
        <div className="menu-top-i p-3">
          <div className="logo-w">
            <Link to="/" className="logo">
              <img src={logo} style={{ width: "100px", borderRadius: "50%" }} />
            </Link>
          </div>
          <ul
            className={`main-menu ${
              activeSubMenu.includes("Menu") ? "has-active" : ""
            }`}
          >
            <li className="">
              <Link to="/dashboard" className="text-decoration-none">
                <div className="icon-w">
                  <div className="os-icon os-icon-window-content" />
                </div>
                <span>Dashboard</span>
              </Link>
            </li>

            <li
              className={`cursor-pointer has-sub-menu ${
                activeSubMenu == "firstMenu" ? "active" : ""
              }`}
              onClick={() => setActiveSubMenu("firstMenu")}
              style={{ cursor: "pointer!important" }}
            >
              {/* <li className={`has-sub-menu `}> */}

              <a className="cursor-pointer">
                <div className="icon-w " style={{ cursor: "pointer" }}>
                  <div className="os-icon os-icon-hierarchy-structure-2" />
                </div>
                <span style={{ cursor: "pointer" }}>Loan Finance</span>
              </a>
              <ul className="sub-menu">
                <Link to="/add-loan" className="text-decoration-none">
                  <li>
                    <a>Apply Loan </a>
                  </li>
                </Link>
                <Link to="/loan-listing">
                  <li>
                    <a>Loan List </a>
                  </li>
                </Link>
                {role == "2" && (
                  <>
                     <Link
                    to="/allpaymentwithdrawals"
                    className="text-decoration-none"
                  >
                    <li>
                      <a>Payment Withdrawals </a>
                    </li>
                  </Link>
                    <Link
                    to="/loanledger"
                    className="text-decoration-none"
                  >
                    <li>
                      <a> Loan Ledger </a>
                    </li>
                  </Link>
                  </>
               
                  
                )}
                {/* <Link to="/pending-loan-listing" className="text-decoration-none">
                <li>
                  <a>Pending Loan List </a>
                </li>
              </Link>
              <Link to="/running-loan-listing">
                <li>
                  <a>Running Loan List </a>
                </li>
              </Link>
              <Link to="/reject-loan-listing" className="text-decoration-none">
                <li>
                  <a>Reject Loan List </a>
                </li>
              </Link>
              <Link to="/agree-loan-listing" className="text-decoration-none">
                <li>
                  <a>Agree Loan List </a>
                </li>
              </Link> */}
              </ul>
            </li>
            <li
              className={` has-sub-menu ${
                activeSubMenu == "secondMenu" ? "active" : ""
              }`}
              onClick={() => setActiveSubMenu("secondMenu")}
            >
              <a className="text-decoration-none cursor-pointer">
                <div className="icon-w" style={{ cursor: "pointer" }}>
                  <div className="os-icon os-icon-delivery-box-2" />
                </div>
                <span style={{ cursor: "pointer" }}>RTO</span>
              </a>
              <ul className="sub-menu">
                <Link to="/add-rto" className="text-decoration-none">
                  <li>
                    <a>Apply RC </a>
                  </li>
                </Link>
                <Link to="/rto-listing">
                  <li>
                    <a>RTO List </a>
                  </li>
                </Link>
                {role == "2" && (
                  <>
                    <Link to="/add-rto-agent">
                      <li>
                        <a>Add RTO Agent </a>
                      </li>
                    </Link>
                    <Link to="/rto-agent-list">
                      <li>
                        <a>RTO Agent List </a>
                      </li>
                    </Link>
                    <Link to="/rto-agent-payment">
                      <li>
                        <a>RTO Agent Payment </a>
                      </li>
                    </Link>
                    <Link to="/rto-agent-payment-history">
                      <li>
                        <a>Agent/Applicant Payment History</a>
                      </li>
                    </Link>
                  </>
                )}
              </ul>
            </li>

            {role == "2" && (
              <>
                <li
                  className={`has-sub-menu ${
                    activeSubMenu == "thirdMenu" ? "active" : ""
                  } `}
                  onClick={() => setActiveSubMenu("thirdMenu")}
                >
                  <a href="#" className="text-decoration-none">
                    <div className="icon-w">
                      <div className="os-icon os-icon-newspaper" />
                    </div>
                    <span>Insurance</span>
                  </a>
                  <ul className="sub-menu">
                    <Link to="/add-insurance">
                      <li>
                        <a>Add Insurance </a>
                      </li>
                    </Link>
                    <Link to="/insurance-listing">
                      <li>
                        <a>Insurance List </a>
                      </li>
                    </Link>
                  </ul>
                </li>
                <li
                  className={`has-sub-menu ${
                    activeSubMenu == "fourthMenu" ? "active" : ""
                  } `}
                  onClick={() => setActiveSubMenu("fourthMenu")}
                >
                  <a href="#" className="text-decoration-none">
                    <div className="icon-w">
                      <div className="os-icon os-icon-pencil-12" />
                    </div>
                    <span>Employee</span>
                  </a>
                  <ul className="sub-menu">
                    <Link to="/add-employee">
                      <li>
                        <a>Add Employee</a>
                      </li>
                    </Link>
                    <Link to="/employee-list">
                      <li>
                        <a>Employee List</a>
                      </li>
                    </Link>
                    <Link to="/generate-salary">
                      <li>
                        <a>Generate Salary</a>
                      </li>
                    </Link>
                    <Link to="/view-salary">
                      <li>
                        <a>Office Employee Salary</a>
                      </li>
                    </Link>
                    <Link to="/view-Sale-salary">
                      <li>
                        <a>Field Employee Salary</a>
                      </li>
                    </Link>
                  </ul>
                </li>
                <li
                  className={`has-sub-menu ${
                    activeSubMenu == "fifthMenu" ? "active" : ""
                  } `}
                  onClick={() => setActiveSubMenu("fifthMenu")}
                >
                  <a href="#" className="text-decoration-none">
                    <div className="icon-w">
                      <div className="os-icon os-icon-user-male-circle" />
                    </div>
                    <span>Account</span>
                  </a>
                  <ul className="sub-menu">
                    <Link to="/add-head">
                      <li>
                        <a>Add Head </a>
                      </li>
                    </Link>
                    <Link to="/add-subhead">
                      <li>
                        <a>Add Subhead </a>
                      </li>
                    </Link>
                    <Link to="/add-expence">
                      <li>
                        <a>Income/Expences </a>
                      </li>
                    </Link>
                    <Link to="/expence-listing">
                      <li>
                        <a>Expences Listing </a>
                      </li>
                    </Link>
                  </ul>
                </li>

                <li
                  className={`has-sub-menu ${
                    activeSubMenu == "fifthMenu" ? "active" : ""
                  } `}
                  onClick={() => setActiveSubMenu("fifthMenu")}
                  style={{ cursor: "pointer" }}
                >
                  <a>
                    <div className="icon-w">
                      <div className="os-icon os-icon-hierarchy-structure-2" />
                    </div>
                    <span>General Settings</span>
                  </a>
                  <ul className="sub-menu">
                    <Link to="/add-doc">
                      <li>
                        <a>Documents</a>
                      </li>
                    </Link>
                    <Link to="/add-company">
                      <li>
                        <a>I/F Company Add</a>
                      </li>
                    </Link>
                    <Link to="/company-listing">
                      <li>
                        <a>I/F Company List</a>
                      </li>
                    </Link>
                  </ul>
                </li>
              </>
            )}
          </ul>
          <div style={{ position: "relative" }}>
            <div
              className="logged-user-w "
              onClick={() => setProfileDrop(!profileDrop)}
            >
              <div className="avatar-w">
                <img alt src="img/avatar1.jpg" />
              </div>
            </div>
            <ul
              className={`shadow ${profileDrop == true ? "d-block" : "d-none"}`}
              style={{
                position: "absolute",
                backgroundColor: "#0061da",
                color: "white",
                padding: "10px",
                listStyleType: "none",
                zIndex: "10",
                width: "200px",
                right: "0px",
                textDecoration: "none",
              }}
            >
              <div className={`logged-user-w `}>
                <div className="logged-user-i">
                  <div className={`logged-user-menu`}>
                    <div className="logged-user-avatar-info">
                      <div className="avatar-w">
                        <img alt src="img/avatar1.jpg" />
                      </div>
                      <div className="logged-user-info-w">
                        <div className="logged-user-name">{user?.name}</div>
                        <div className="logged-user-role">{user?.username}</div>
                      </div>
                    </div>
                    {/* <div className="bg-icon">
            <i className="os-icon os-icon-wallet-loaded" />
          </div> */}
                    <ul
                      className="p-0 text-start"
                      style={{
                        listStyleType: "none",
                        color: "white",
                        textDecoration: "none",
                      }}
                    >
                      <li className="py-2">
                        <Link
                          onClick={() => {
                            if (role !== "2") {
                              navigate("/add-employee");
                            } else {
                              navigate("/profile");
                            }
                          }}
                          className="text-decoration-none text-white p-2 py-3"
                        >
                          <i className="os-icon os-icon-signs-11" />
                          <span>Profile</span>
                        </Link>
                      </li>
                    
                        <li className="py-2">
                          <Link
                            to="/change-password"
                            className="text-white text-decoration-none p-2 py-3"
                          >
                            <i className="os-icon os-icon-signs-11" />
                            <span>Change Password</span>
                          </Link>
                        </li>
                      

                      <li className="py-2">
                        <a
                          href="#"
                          className="text-white text-decoration-none p-2 py-3"
                          onClick={() => LogOut()}
                        >
                          <i className="os-icon os-icon-signs-11" />
                          <span>Logout</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuTop;
