import React, { useEffect, useRef, useState } from "react";
import Layout from "../../../components/Layout";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import moment from "moment";
const initialState = {
  remark: "",
  transactionId: "",
  transactionDate: "",
  amount: 0,
  otherAmount: 0,
  paymentMode: "",
  paymentModeType: "",
  agentId: "",
  checkNo: "",
  upiId: "",
  status: "Active",
};
const AddPayment = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [rtoDetail, setRTODetail] = useState();
  const [selectedAgent, setSelectedAgent] = useState({});
  const [remainingAmount, setRemainingAmount] = useState();
  const [values, setValues] = useState(initialState);
  const [agentPayments, setAgentPayments] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [totalPayableAmount, setTotalPayableAmount] = useState([]);
  const fuseOptions = {
    keys: ["agentName"],
  };
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const formatResult = (item) => {
    return (
      <span style={{ display: "block", textAlign: "left" }}>
        <p className="m-0">name: {item?.name}</p>
        <p className="m-0">Mobile No.: {item?.mobile_no}</p>
      </span>
    );
  };
  const savePayment = (step) => {
    const token = localStorage.getItem("token");
    console.log("values", values);

    if (step != "rtoApplicant" && !state?._id && !values?.agentId) {
      toast.error("Agent is  required !");
      return;
    }
    if (state?._id) {
      values.rtoId = selectedAgent._id;
    }
    if (values.amount) {
      values.amount = parseInt(values.amount);
    }
    if (remainingAmount) {
      values.remaining_amount = parseInt(remainingAmount);
    }

    console.log(values);
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/addRtoAgentApplicantPaymentHistory`, values, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          if (step !== "rtoApplicant") {
            navigate("/rto-agent-payment-history");
            console.log("in pay");
          }
          setLoading(false);
          setValues(initialState);
          // setCompanyId(response.data.data._id);
          toast.success(response.data.message);
          if (state?._id) {
            getRtoByRtoId();
          }
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const getAgent = () => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}admin/getAllRTOAgentPayment`,
        {},
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setAgentList(response.data.data);

          console.log(response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleOnSearch = (string, results) => {
    console.log(string, results);
  };
  const handleOnSelect = (item) => {
    console.log(item);
    setSelectedAgent(item);
    setValues({
      ...values,
      agentId: item.agentId,
    });
    AgentPayment(item.agentId);
    console.log(item);
  };

  const AgentPayment = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}admin/getAgentPaymentByAgentId/${id}`,
        {
          headers: {
            "Content-Type": "Application/json",
            "x-access-token": `${localStorage.getItem("token")}`,
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setAgentPayments(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const getRtoByRtoId = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getRTOByRTOId/${state?._id}`, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setRTODetail(response.data.data[0]);
          console.log("valuesrto", values);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (state) {
      setValues({ ...state.item } || { ...state });
      setSelectedAgent(state.item?.agentData?.[0] || state);
      console.log("state", state);
    }
    getAgent();
    if (state?._id) {
      getRtoByRtoId();
    }

    if (rtoDetail?.totalApplicantpaidAmount) {
      setValues((prevState) => ({
        ...prevState,
        amount:
          parseInt(rtoDetail?.work_fee) -
          parseInt(rtoDetail?.totalApplicantpaidAmount),
      }));
    } else {
      setValues((prevState) => ({
        ...prevState,
        amount: parseInt(rtoDetail?.work_fee),
      }));
    }
  }, []);

  const backButton = () => {
    // setRTODetail({...rtoDetail,step:"secondStep"})
    navigate("/add-rto", { state: { ...rtoDetail, step: "secondStep" } });
  };
  useEffect(() => {
    if (values.amount) {
      const remaining_amount =
        parseInt(rtoDetail?.work_fee) -
        parseInt(values.amount) -
        parseInt(rtoDetail?.totalApplicantpaidAmount);
      setRemainingAmount(remaining_amount);
    } else {
      const remaining_amount =
        parseInt(rtoDetail?.work_fee) -
        parseInt(rtoDetail?.totalApplicantpaidAmount);
      setRemainingAmount(remaining_amount);
    }
  }, [rtoDetail?.work_fee, rtoDetail?.rtoApplicantPayment, values.amount]);

  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <div className="element-box">
                  {rtoDetail ? (
                    ""
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-12 mb-4">
                          <ReactSearchAutocomplete
                            items={agentList}
                            onSearch={handleOnSearch}
                            // fuseOptions={fuseOptions}
                            // onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            placeholder="Search Agent Name"
                            // onFocus={handleOnFocus}
                            style={{ zIndex: 1 }}
                            autoFocus
                            formatResult={formatResult}
                          />
                        </div>
                      </div>

                      {selectedAgent.name && (
                        <div className="table-responsive">
                          <table className="table table-lightborder">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Mobile Number</th>
                                <th>District</th>
                                <th>Bank name</th>
                                <th>Payable Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{selectedAgent?.name}</td>
                                <td>{selectedAgent?.mobile_no}</td>
                                <td>{selectedAgent?.agentDistrict}</td>

                                <td>{selectedAgent?.agentBankName}</td>

                                <td>{parseInt(selectedAgent?.amount)}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  )}

                  <div>
                    {rtoDetail ? (
                      <h5 className="form-header">RTO Applicant Payment </h5>
                    ) : (
                      <h5 className="form-header">
                        RTO Agent Payment Withdraw{" "}
                      </h5>
                    )}
                    <div className="row">
                      {rtoDetail && (
                        <>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor>Work Fees:</label>
                              <input
                                className="form-control"
                                placeholder="Enter work Fee"
                                name="work_fee"
                                onChange={(e) => handleInputChange(e)}
                                value={rtoDetail?.work_fee}
                                type="number"
                              />
                            </div>
                          </div>
                          {rtoDetail.rtoApplicantPayment.length > 0 && (
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Remaining Amount</label>
                                <input
                                  className="form-control"
                                  placeholder=""
                                  type="number"
                                  // onChange={(e) => handleInputChange(e)}
                                  name="remainingAmount"
                                  value={remainingAmount}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Recieved Amount:</label>
                          <input
                            className="form-control"
                            placeholder="Enter Company Name"
                            name="amount"
                            onChange={(e) => handleInputChange(e)}
                            value={values.amount}
                            type="number"
                          />
                        </div>
                      </div>

                      {rtoDetail?.rtoApplicantPayment?.length <= 0 && (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="">Remaining Amount</label>
                            <input
                              className="form-control"
                              placeholder=""
                              type="number"
                              // onChange={(e) => handleInputChange(e)}
                              name="remainingAmount"
                              value={remainingAmount}
                            />
                          </div>
                        </div>
                      )}

                      {rtoDetail ? (
                        <>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">Amount Received Date</label>
                              <input
                                className="form-control"
                                placeholder=""
                                type="date"
                                onChange={(e) => handleInputChange(e)}
                                name="amount_received_date"
                                value={moment(
                                  values.amount_received_date
                                ).format("YYYY-MM-DD")}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Other Amount:</label>
                            <input
                              className="form-control"
                              placeholder="Enter other amount if any"
                              name="otherAmount"
                              onChange={(e) => handleInputChange(e)}
                              value={values.otherAmount}
                              type="number"
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Any Remark</label>
                          <input
                            className="form-control"
                            placeholder="Enter Remark "
                            name="remark"
                            onChange={(e) => handleInputChange(e)}
                            value={values.remark}
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Payment Mode</label>
                          <select
                            className="form-control"
                            onChange={(e) => handleInputChange(e)}
                            name="paymentMode"
                            value={values.paymentMode}
                          >
                            <option value="">--select--</option>
                            <option value="Cash">Cash</option>
                            <option value="Online">Online</option>
                            <option value="Bank Deposit">Bank Deposit</option>
                            <option value="Check">Check</option>
                          </select>
                        </div>
                      </div>
                      {values.paymentMode == "Online" && (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>Payment Mode Type</label>
                            <select
                              className="form-control"
                              onChange={(e) => handleInputChange(e)}
                              name="paymentModeType"
                              value={values.paymentModeType}
                            >
                              <option value="">--select--</option>
                              <option value="NEFT">NEFT</option>
                              <option value="UPI">UPI</option>
                            </select>
                          </div>
                        </div>
                      )}
                      {values.paymentMode != "" && (
                        <>
                          {values.paymentMode == "Check" && (
                            <>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label htmlFor>Check No </label>
                                  <input
                                    class="form-control"
                                    placeholder="Enter Check No"
                                    type="text"
                                    onChange={(e) => handleInputChange(e)}
                                    name="checkNo"
                                    value={values.checkNo}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {values.paymentMode == "Online" &&
                        values.paymentModeType == "NEFT" && (
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor>Transaction Id</label>
                              <input
                                class="form-control"
                                placeholder="Enter Transaction Id"
                                type="text"
                                onChange={(e) => handleInputChange(e)}
                                name="transactionId"
                                value={values.transactionId}
                              />
                            </div>
                          </div>
                        )}
                      {values.paymentMode == "Online" &&
                        values.paymentModeType == "UPI" && (
                          <>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor>UPI Id/UPI Name</label>
                                <input
                                  class="form-control"
                                  placeholder=""
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="upiId"
                                  value={values.upiId}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor>Transaction Id</label>
                                <input
                                  class="form-control"
                                  placeholder="Enter Transaction Id"
                                  type="text"
                                  onChange={(e) => handleInputChange(e)}
                                  name="transactionId"
                                  value={values.transactionId}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      {values.paymentMode != "Cash" && (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor>
                              {values.paymentMode == "Check"
                                ? "Check"
                                : "Transaction"}{" "}
                              Date
                            </label>
                            <input
                              class="form-control"
                              placeholder=""
                              type="date"
                              onChange={(e) => handleInputChange(e)}
                              name="transactionDate"
                              value={values.transactionDate}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="form-buttons-w">
                      {selectedAgent && !rtoDetail && (
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={() => savePayment()}
                        >
                          {loading ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      )}
                      &nbsp;&nbsp;
                      {rtoDetail && (
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={() => savePayment("rtoApplicant")}
                        >
                          {loading ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      )}
                      &nbsp;&nbsp;
                      {rtoDetail && (
                        <>
                          <button
                            className="btn btn-primary "
                            onClick={() => backButton()}
                          >
                            Back
                          </button>
                          &nbsp;&nbsp;
                          <button
                            className="btn btn-primary "
                            onClick={() => backButton()}
                          >
                            Next
                          </button>
                        </>
                      )}
                    </div>
                  </div>

                  {rtoDetail?.rtoApplicantPayment && (
                    <>
                      <fieldset className="mt-4">
                        <legend>
                          <span> RTO Work Payment Detail</span>
                        </legend>

                        <div className="table-responsive mt-5">
                          <table className="table table-lightborder">
                            <thead>
                              <tr>
                                <th>Work Fees</th>
                                <th>Paid Amount</th>
                                <th>Remaining Amount</th>
                                <th>Other Amount</th>
                                <th>Payment Mode</th>
                                <th>Transaction Id</th>
                                <th>Transaction Date</th>
                                <th>Check No</th>
                                <th>UPI Id</th>
                                <th>Remark</th>
                                <th>Action</th>

                                {/* {selectedAgent?.agentBankName&&<th>Bank name</th>}  
      <th>Payable Amount</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {rtoDetail?.rtoApplicantPayment?.map((item) => (
                                <>
                                  <tr>
                                    <td>{rtoDetail?.work_fee}</td>
                                    <td>{item?.amount}</td>
                                    <td>{item?.remaining_amount}</td>

                                    <td>{item?.otherAmount}</td>
                                    <td>{item?.paymentMode}</td>
                                    <td>{item?.transactionId}</td>
                                    <td>{item?.transactionDate}</td>
                                    <td>{item?.checkNo}</td>
                                    <td>{item?.upiId}</td>

                                    <td>{item?.remark}</td>
                                    <th>
                                      <button
                                        onClick={() =>
                                          navigate("/reciept", {
                                            state: { item, rtoDetail },
                                          })
                                        }
                                      >
                                        Reciept
                                      </button>
                                    </th>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </fieldset>
                    </>
                  )}

                  {/* agents payments list  */}

                  {agentPayments.length > 0 && (
                    <fieldset className="mt-4">
                      <legend>
                        <span> Agents Payments</span>
                      </legend>

                      <div className="table-responsive mt-5">
                        <table className="table table-lightborder">
                          <thead>
                            <tr>
                              <th> Amount</th>
                              <th>Remaining Amount</th>
                              <th>Other Amount</th>
                              <th>Payment Mode</th>
                              <th>Transaction Id</th>
                              <th>Transaction Date</th>
                              <th>Check No</th>
                              <th>UPI Id</th>
                              <th>Remark</th>

                              {/* {selectedAgent?.agentBankName&&<th>Bank name</th>}  
<th>Payable Amount</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {agentPayments?.map((item) => (
                              <>
                                <tr>
                                  <td>{item?.amount}</td>
                                  <td>{item?.remaining_amount}</td>

                                  <td>{item?.otherAmount}</td>
                                  <td>{item?.paymentMode}</td>
                                  <td>{item?.transactionId}</td>
                                  <td>{item?.transactionDate}</td>
                                  <td>{item?.checkNo}</td>
                                  <td>{item?.upiId}</td>

                                  <td>{item?.remark}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </fieldset>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* /////////////// category listing  start */}
    </Layout>
  );
};

export default AddPayment;
