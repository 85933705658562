import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import { Link } from "react-browser-router";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import district_tehsil from "../data/district-tehsil.json";
import indianStates from "../data/IndianStates.json";
import moment from "moment";
import BasicDetails from "../../components/RTO/BasicDetails";
import VehicalDetail from "../../components/RTO/VehicalDetail";
import RTOExpences from "../../components/RTO/RTOExpences";
import RCRecieved from "../../components/RTO/RCRecieved";
import { useSelector } from "react-redux";

const initialState = {
  agentId: "",
  name: "",
  email: "",
  mobile_no: "",
  alternative_mobile_no: "",
  dob: "",
  pincode: "",
  state: "Madhya Pradesh",
  district: "",
  tehsil: "",
  tehsil1: "",
  address: "",
  sourcing: "",
  otherSrc: "",
  vehicle_no: "",
  model: "",
  vehicle_name:"",
  distt_rto: "",
  work_fee: "",
  rtoServiceCharge: "",
  amount_received: 0,
  amount_received_date: "",
  remaining_amount: "",
  broker_insentive: "",

  broker_name: "",
  rc_card_status: "Pending",
  rc_received_date: "",
  rc_card_relative_reciever:"",
  rc_received_date_by_rto_agent:"",
  rc_recieved_status_by_rtoAgent:"",
  rc_card_receiver_customer_name: "",
  rc_card_receiver_customer_mobile_no: "",
  remark: "",
  TO_fees: "",
  TO_AddressChange_Fees: "",
  CO_fees: "",
  DRC_Fees: "",
  HSRP_Fees: "",
  Open_BankAccount: "",
  Tax: "",
  otherExpences: "",
  totalRtoExpence: "",
  rtoDeductionCharges: "",
  rtoServiceCharge: "",
  rtoServiceOtherCharge: "",
  totalIncome: "",
  form_34: "",
  form_35: "",
  vehical_renewal: "",
  form_31: "",
  valuation_fee: "",
  total_expence: "",
  status: "Active",
};
function AddRTO() {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const { state } = useLocation();
  const { role } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  const [totalRecievedAmount, setTotalRecievedAmount] = useState(
    values?.amount_received
  );
  const [totalSpendAmount, setTotalSpendAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState();
  const [image, setImage] = useState("");
  const fileInputRef = useRef(null);
  const [districtTehsils, setDistrictTehsils] = useState([]);
  const [employees, setEmployees] = useState("");
  const [activeTab, setActiveTab] = useState("firstStep");
  const [states, setStates] = useState([]);
  const [rtoId, setRtoId] = useState("");

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const handleTabClick = (tabName) => {
    // console.log("okkkk", tabName);
    if (tabName === "firstStep") {
      if (!values.name) {
        toast.error("Name is required");
        return false;
      }

      if (!values.mobile_no) {
        toast.error("Mobile Number is required");
        return false;
      } else {
        return true;
      }
    } else if (tabName === "secondStep") {
      if (!values.name) {
        toast.error("Name is required");
        return false;
      }

      if (!values.mobile_no) {
        toast.error("Mobile Number is required");
        return false;
      } else {
        return true;
      }
    } else if (tabName === "thirdStep") {
      if (!values.vehicle_type) {
        toast.error("Vehicle Type is required");
        return;
      }

      if (!values.vehicle_no) {
        toast.error("Vehicle No is required");
        return false;
      }
      if (!values.model) {
        toast.error("Model  is required");
        return false;
      }
      if (!values.distt_rto) {
        toast.error("Distt RTO is required");
        return false;
      } else {
        return true;
      }
    } else if (tabName === "fourthStep") {
      return true;
    } else if (tabName === "final") {
      if (values.work_fee - values.totalApplicantpaidAmount !== 0) {
        toast.error("Pay due to process further ");
        return false;
      } else {
        return true;
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setValues({
        ...values,
        image: [...values.image, ...files],
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  // add update product
  const SaveRTO = (step) => {
    if (values.totalRtoExpence > values.work_fee) {
      toast.error("balance is not sufficient");
      return;
    }
    let obj = {};

    console.log(step);
    if (step !== "rtoPayment") {
      if (!handleTabClick(step)) {
        console.log("in");
        return;
      }
    }

    const token = localStorage.getItem("token");
    let api = "addRTO";
    if (rtoId) {
      api = "updateRTO";
    }
    obj.name = values.name;
    obj.email = values.email;
    obj.mobile_no = values.mobile_no;
    obj.alternative_mobile_no = values.alternative_mobile_no;
    if (values.dob != null) {
      obj.dob = values.dob;
    }
    obj.pincode = values.pincode;
    obj.state = values.state;
    obj.district = values.district;
    obj.tehsil = values.tehsil;
    obj.address = values.address;
    obj.recieptId = values.recieptId;
    if (values.TO_fees) {
      obj.TO_fees = values.TO_fees;
    }
    if (values.agentId != "") {
      obj.agentId = values.agentId;
    }
    if (values.TO_AddressChange_Fees) {
      obj.TO_AddressChange_Fees = values.TO_AddressChange_Fees;
    }
    if (values.CO_fees) {
      obj.CO_fees = values.CO_fees;
    }
    if (values.HSRP_Fees) {
      obj.HSRP_Fees = values.HSRP_Fees;
    }
    if (values.DRC_Fees) {
      obj.DRC_Fees = values.DRC_Fees;
    }
    if (values.otherExpences) {
      obj.otherExpences = values.otherExpences;
    }
    if (values.RTO_Stamp_Fees) {
      obj.RTO_Stamp_Fees = values.RTO_Stamp_Fees;
    }
    if (values.form_34) {
      obj.form_34 = values.form_34;
    }
    if (values.form_35) {
      obj.form_35 = values.form_35;
    }
    if (values.vehicle_name) {
      obj.vehicle_name = values.vehicle_name;
    }

    if (values.vehical_renewal) {
      obj.vehical_renewal = values.vehical_renewal;
    }
    if (values.form_31) {
      obj.form_31 = values.form_31;
    }
    if (values.totalRtoExpence) {
      obj.totalRtoExpence = values.totalRtoExpence;
    }
    if (values.totalIncome) {
      obj.totalIncome = values.totalIncome;
    }
    if (values.Tax) {
      obj.Tax = values.Tax;
    }
    if (values.Open_BankAccount) {
      obj.Open_BankAccount = values.Open_BankAccount;
    }
    if (values.rtoServiceOtherCharge) {
      obj.rtoServiceOtherCharge = values.rtoServiceOtherCharge;
    }
    obj.vehicle_type = values.vehicle_type;
    obj.vehicle_no = values.vehicle_no;

    obj.model = values.model;
    obj.distt_rto = values.distt_rto;
    obj.work_fee = values.work_fee;
    obj.rtoServiceCharge = values.rtoServiceCharge;
    obj.amount_received = values.amount_received;
    if (values.amount_received_date != null) {
      obj.amount_received_date = values.amount_received_date;
    }
    if (values.remaining_amount) {
      obj.remaining_amount = values.remaining_amount;
    }
    obj.broker_insentive = values.broker_insentive;
    obj.broker_name = values.broker_name;
    obj.rc_card_status = values.rc_card_status;
    if (values.rc_received_date != null) {
      obj.rc_received_date = values.rc_received_date;
    }
    obj.rc_card_receiver_customer_name = values.rc_card_receiver_customer_name;
    obj.rc_card_receiver_customer_mobile_no =
      values.rc_card_receiver_customer_mobile_no;
    obj.status = values.status;
    if (rtoId) {
      obj.id = rtoId;
    }

    axios
      .post(`${BASE_URL}admin/${api}`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          if (step === "rtoPayment") {
            navigate("/rto-agent-payment", { state: response.data.data });
          } else {
            if (step === "final") {
              navigate("/rto-listing");
            }
            setRtoId(response.data.data._id);
            toast.success(response.data.message);
            getRtoByRtoId(response.data.data._id);
            if (step == "secondStep") {
              AddTehsils();
              if(role=="1"){
                navigate("/rto-listing");
              }
            }
            setActiveTab(step);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getRtoByRtoId = (id) => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getRTOByRTOId/${id}`, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setValues({ ...response.data.data[0] });
          console.log("values", values);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const AddTehsils = () => {
    const token = localStorage.getItem("token");
    if (values.tehsil1) {
      setLoading(true);
      axios
        .post(
          `${BASE_URL}admin/updateTehsils`,
          { tehsil: values.tehsil1, district: values.district },
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "Application/json",
              version: "1.0.0",
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            setLoading(false);
          } else {
            if (response.data.error_code == 461) {
              navigate("/login");
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message);
        });
    }
  };

  useEffect(() => {
    if (state) {
      if (state.step) {
        setActiveTab(state?.step);
      }
      setValues({ ...state?.item });
      if (state?.item?._id) {
        setRtoId(state?.item?._id);
      }
      if (state?._id) {
        setRtoId(state?._id);
      }
    }
  }, []);
  useEffect(() => {
    getRtoByRtoId(rtoId);
  }, [rtoId]);

  useEffect(() => {
    const totalRtoExpence =
      (parseInt(values?.TO_fees) || 0) +
      (parseInt(values?.TO_AddressChange_Fees) || 0) +
      (parseInt(values?.CO_fees) || 0) +
      (parseInt(values?.DRC_Fees) || 0) +
      (parseInt(values?.HSRP_Fees) || 0) +
      (parseInt(values?.RTO_Stamp_Fees) || 0) +
      (parseInt(values?.otherExpences) || 0) +
      (parseInt(values?.Tax) || 0) +
      (parseInt(values?.Open_BankAccount) || 0) +
      (parseInt(values?.rtoServiceCharge) || 0) +
      (parseInt(values?.form_34) || 0) +
      (parseInt(values?.form_35) || 0) +
      (parseInt(values?.vehical_renewal) || 0) +
      (parseInt(values?.form_31) || 0);

    setValues((prevValues) => ({
      ...prevValues,
      totalRtoExpence,
    }));
  }, [
    values?.TO_fees,
    values?.TO_AddressChange_Fees,
    values?.CO_fees,
    values?.DRC_Fees,
    values?.HSRP_Fees,
    values?.RTO_Stamp_Fees,
    values?.Tax,
    values?.Open_BankAccount,
    values?.otherExpences,
    values?.rtoServiceCharge,
    values?.form_31,
    values?.form_34,
    values?.form_35,
    values?.vehical_renewal,
  ]);

  useEffect(() => {
    const rtoDeductionCharges =
      (parseInt(values?.TO_fees) || 0) +
      (parseInt(values?.TO_AddressChange_Fees) || 0) +
      (parseInt(values?.CO_fees) || 0) +
      (parseInt(values?.DRC_Fees) || 0) +
      (parseInt(values?.HSRP_Fees) || 0) +
      (parseInt(values?.RTO_Stamp_Fees) || 0) +
      (parseInt(values?.otherExpences) || 0) +
      (parseInt(values?.Tax) || 0) +
      (parseInt(values?.Open_BankAccount) || 0) +
      (parseInt(values?.form_34) || 0) +
      (parseInt(values?.form_35) || 0) +
      (parseInt(values?.vehical_renewal) || 0) +
      (parseInt(values?.form_31) || 0);

    setValues((prevValues) => ({
      ...prevValues,
      rtoDeductionCharges,
    }));

    console.log(values.rtoDeductionCharges);
  }, [
    values?.TO_fees,
    values?.TO_AddressChange_Fees,
    values?.CO_fees,
    values?.DRC_Fees,
    values?.HSRP_Fees,
    values?.RTO_Stamp_Fees,
    values?.Tax,
    values?.Open_BankAccount,
    values?.otherExpences,
    values?.form_31,
    values?.form_34,
    values?.form_35,
    values?.vehical_renewal,
  ]);

  useEffect(() => {
    const totalIncome =
      (parseInt(values?.work_fee) || 0) -
      (parseInt(values?.totalRtoExpence) || 0);
    setValues((prevValues) => ({
      ...prevValues,
      totalIncome,
    }));
  }, [values?.work_fee, values?.totalRtoExpence]);
  useEffect(() => {
    const remaining_amount =
      (parseInt(values?.work_fee) || 0) -
      (parseInt(values?.amount_received) || 0);
    setValues((prevValues) => ({
      ...prevValues,
      remaining_amount,
    }));
    // setRemainingAmount(remaining_amount);
  }, [values?.work_fee, values?.amount_received]);

  return (
    <Layout>
      <div className="content-i no-print-content">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div>
                <div className="steps-w w-100">
                  <div className="step-triggers">
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${
                        activeTab == "firstStep" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("firstStep")}
                    >
                      Basic Information
                    </a>
                    {
                      role!="2"?"":
                      <>
                         <a
                      href="javascript:void(0)"
                      className={`step-trigger ${
                        activeTab == "secondStep" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("secondStep")}
                    >
                      RTO Expence Charge
                    </a>
                    <a
                      href="javascript:void(0)"
                      className={`step-trigger ${
                        activeTab == "thirdStep" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("thirdStep")}
                    >
                      Rc Recieved Details
                    </a>
                      </>
                    }
                 
                  </div>
                  <div className="step-contents">
                    <div
                      className={`step-content  ${
                        activeTab == "firstStep" ? "active" : ""
                      }`}
                      id="stepContent1"
                      style={{
                        display: activeTab == "firstStep" ? "block " : "none",
                      }}
                    >
                      <BasicDetails
                        handleInputChange={handleInputChange}
                        setActiveTab={setActiveTab}
                        values={values}
                        loading={loading}
                        SaveRTO={SaveRTO}
                      />

                      <VehicalDetail
                        handleInputChange={handleInputChange}
                        setActiveTab={setActiveTab}
                        values={values}
                        loading={loading}
                        SaveRTO={SaveRTO}
                      />
                    </div>
                    <div
                      className={`step-content ${
                        activeTab == "secondStep" ? "active" : ""
                      }`}
                      id="stepContent3"
                      style={{
                        display: activeTab == "secondStep" ? "block" : "none",
                      }}
                    >
                      <RTOExpences
                        handleInputChange={handleInputChange}
                        setActiveTab={setActiveTab}
                        values={values}
                        loading={loading}
                        SaveRTO={SaveRTO}
                      />
                    </div>
                  </div>

                  <div
                    className={`step-content ${
                      activeTab == "thirdStep" ? "active" : ""
                    }`}
                    id="stepContent3"
                    style={{
                      display: activeTab == "thirdStep" ? "block" : "none",
                    }}
                  >
                    <RCRecieved
                      handleInputChange={handleInputChange}
                      setActiveTab={setActiveTab}
                      values={values}
                      loading={loading}
                      SaveRTO={SaveRTO}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddRTO;
