import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout';
import {ExportToExcel} from "../../Utilities/CommonMethods"
import moment from 'moment';

function AllPaymentWithdrawls() {
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentWithdrawals, setPaymentWithdrawals] = useState([]);
  const [pages, setPages]=useState(1)

    const PaymentWithdrawals=()=>{
        axios.get(`${process.env.REACT_APP_BASE_URL}admin/getAllPaymentWithdrawals`,{
            params:{
                page: currentPage,
                limit: 8,
            },
            headers:{
                "Content-Type":"Application/json",
                "x-access-token":`${localStorage.getItem("token")}`,
                version:"1.0.0"
            }
        }).then((response)=>{
            if(response.data.success){
                setPaymentWithdrawals(response.data.data)
                setPages(response.data.remainingCount)
            }
        })
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
      };
      useEffect(()=>{
        PaymentWithdrawals();
      },[currentPage])
  return (
    <Layout>
    <div className="content-i">
      <div className="content-box">
        <div className="element-wrapper">
          <div className="element-box">
          <h5 className="form-header text-bold">Payment Withdrawal List</h5>
          <div className="text-end ">
          <button className="btn btn-primary" onClick={()=>ExportToExcel(paymentWithdrawals)}>Export To CSV</button>

          </div>
            {/* <div className="row d-flex justify-content-between my-4">
              <div className="col">
            
              <input
          type="text"
          placeholder="Search by name, mobile, or vehicle number"
          className="searchLoan"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
              </div>
              <div className="col-3 text-end">
              <select className="form-control searchSelect" name="status" value={status} onChange={(e)=>setStatus(e.target.value)}
                style={{ height:"45px", borderRadius:"0px" }}>
              <option value="" selected="true">Select Loan Status</option>
              <option value="Pending Leed">Pending Leed</option>
              <option value="Rejected Leed">Rejected Leed</option>
              <option value="Running Leed">Running Leed</option>
              <option value="Agree Leed">Agree Leed</option>
             </select>
              </div>
            </div> */}
            <div className="table-responsive">
            <table className="table table-lightborder">
              <thead>
                <tr>
                    <th>Loan Person</th>
                  <th>Withdrawal To</th>

                  <th>Account Holder Name</th>
                  <th>Bank Name</th>
                  <th>Account Number</th>
                  <th>IFSC Code</th>
                  <th>Amount</th>
                  <th>Remark</th>
                  <th>Transaction Date</th>
                </tr>
              </thead>
              <tbody>
                {paymentWithdrawals?.length > 0 &&
                  paymentWithdrawals.map((item, index) => (
                    <tr key={item._id}>
                        <td>
                            {item.loanDetails[0].name}<br/>
                            {item.loanDetails[0].mobile_no}
                        <br />
                        </td>
                      <td>
                        {item.withdrawalTo}
                        <br />
                        {item.withdrawalTo == "Finance Company" &&
                          `Name: ${item.Companies[0]?.company_name}`}
                      </td>
                      <td>{item.accountHolderName}</td>
                      <td>{item.bankName}</td>
                      <td>{item.accountNumber}</td>
                      <td>{item.IfscCode}</td>
                      <td>{item.amount}</td>
                      <td>{item.remark}</td>
                      <td>
                        {moment(item.transactionDate).format("YYYY-MM-DD")}
                      </td>

                      {/* <td>
                        <button className="border-0 bg-white">
                          <i
                            className="os-icon os-icon-pencil-2"
                            onClick={() => WindowScrollTOTOp(item)}
                          />
                        </button>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li
                    class={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                      <>
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      </>
                    )
                  )}
                  <li
                    class={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  )
}

export default AllPaymentWithdrawls