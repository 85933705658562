import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import logo from "../../img/templogo.webp";
import { useSelector } from 'react-redux';
import moment from 'moment';


function SalarySlip() {
    const salaryContent = React.createRef();
    const { state } = useLocation();
  const {profile} = useSelector((state) => state.profile);

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [values, setValues] = useState(profile);
    const [image , setImage]=useState(profile.logo);
    const handlePrintReceipt = useReactToPrint({
        content: () => salaryContent.current,
        documentTitle: "Receipt",
        onBeforeGetContent: () => {
          // Optionally adjust the content before printing
        },
        style: `
              @media print {
                body {
                  background-color: white !important;
                  color: black !important;
                }
    
                .print-content {
                  // display: block !important;
                  background-color: white !important;
                  background-image: none !important;
                  color: black !important;
                  border: none !important;
                  width: 100% !important;
                  height: 100vh !important;
                  padding: 50px !important;
                  padding-top:10px!important;
                }
                .print-content * {
                  // display: block !important;
                }
              }
            `,
      });

      useEffect(()=>{
console.log("123",state);
      },[])
  return (
    <Layout>
          <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="p-4 print-content" ref={salaryContent}>
                <div
                  className="invoice-body justify-content-center"
                  id="receipt-content"
                >
                  <div className="invoice-table">
                  

                    <div className="row ">
                      <div className="col-3">
                        <div className="info-1">
                          <div className="invoice-logo-w">
                            <img alt="" src={image} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="col-9 d-flex align-items-center lh-1">
                        <div>
                          <div className="company-name">
                            <h2>{values?.companyName}</h2>
                            <p className="mb-1">Address: {values?.address} </p>
                           
                            <p className="mb-1"> Email: {values?.email}</p>
                          
                            <p className="mb-1">  Mobile No.: {values?.mobile_no}</p>
                          </div>
                          
                        </div>
                      </div>
                      <div className="col">
                        <div className="info-2 ">
                          <div className="company-address text-capitalize ">
                            <p>
                              <b className="fs-5">Employee Details</b>
                            </p>
                            Name: {state?.item?.employee[0]?.name}<br/>
                            tel. {state?.item?.employee[0]?.mobile_no}<br/>
                            Date of joining : {moment(state?.item?.employee[0]?.joining_date).format("DD MMM YYYY")}<br/>
                           {state?.item?.employee[0].salary!=null&&
                           <>
                           Payable Days : {state?.item?.payable_days}
                           </>} <br/>
                           Pay Period : {state?.item?.salary_year} {state?.item?.salary_month}<br/>
                           
                          </div>
                       
                        </div>
                      </div>
                      <div className="col d-flex justify-contentend align-items-end">
                       <div className="w-100 text-end mb-2"> Employee Id: {state?.item?.employee[0].employeeId}</div>
                      </div>
                    </div>

                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" className="fs-6">Salary</th>
                          <th scope="col" className="fs-6">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{state?.item?.employee[0].commission? "Commission":"Salary"}</td>
                          <td>
                            {" "}
                            <span>
                            {state?.item?.employee[0].commission? state?.item?.employee[0]?.commission+"%":
                            <>
                              <i className="fa fa-inr">
                                {" "}
                               { state?.item?.employee[0]?.salary}
                              </i>
                            </>
}
                            </span>
                          </td>
                        </tr>

                        {
                            state?.item?.employee[0]?.salary&&
                            <>
                            
                          <tr>
                            <td className="text-end">
                              {" "}
                              <b> Leave Amount</b>
                            </td>
                            <td>
                              <b className="">
                                <span>
                                  <i className="fa fa-inr">
                                    {" "}
                                    {state?.item?.leave_amount}
                                  </i>
                                </span>
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-end">
                              {" "}
                              <b> Deduction</b>
                            </td>
                            <td>
                              <b className="">
                                <span>
                                  <i className="fa fa-inr">
                                    {" "}
                                    {state?.item?.employee[0]?.deduction_amount||0}
                                  </i>
                                </span>
                              </b>
                            </td>
                          </tr>
                            </>
                          
                        }
                     
                       
                        <tr>
                          <td className="text-end">
                            {" "}
                            <b> Payable Amount:</b>
                          </td>
                          <td>
                            <b className="">
                              <span>
                                <i className="fa fa-inr">
                                  {" "}
                                  {state?.item?.net_payable_salary}
                                </i>
                              </span>
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    
                    <hr />
                    
                    <div className="invoice-footer">
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button onClick={() => handlePrintReceipt()}>Print</button>
          </div>
        </div>
      </div>
      <style>
        {`
          @media print {
            body {
              background-color: white !important;
              color: black !important;
            }
            
            .print-content {
              // display: block !important;
              background-color: white !important;
              background-image: none !important;
              color: black !important;
              border: none !important;
              width: 100% !important;
              height: 100vh !important;
              padding: 40px !important;
               padding-top:10px!important;
              
            }
            .print-content * {
              // display: block !important;
            }
          }
        `}
      </style>
    </Layout>
  )
}

export default SalarySlip