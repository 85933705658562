import React from 'react';
import Layout from './Layout';
import { useLocation } from 'react-router-dom';

const LedgerTable = () => {
  const {state} = useLocation();
  const tableStyle = {
    marginTop: '50px',
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'center',
  };

  const thStyle = {
    backgroundColor: '#f8f9fa',
    padding: '10px',
    border: '1px solid #dee2e6',
  };

  const tdStyle = {
    padding: '10px',
    border: '1px solid #dee2e6',
  };

  const data = state

  console.log("state",state);

  return (
    <Layout>
  <div className="content-i">
      <div className="content-box">
        <div className="element-wrapper">
          <div className="element-box">
          <h5 className="form-header text-bold">Ledger</h5>
      <table style={tableStyle} className="table table-bordered">
        <thead>
          <tr>
            <th style={thStyle}>Date</th>
            <th style={thStyle}>Description</th>
            <th style={thStyle}>Debit</th>
            <th style={thStyle}>Credit</th>
            <th style={thStyle}>Balance</th>
          </tr>
        </thead>
        <tbody>
          {data?.length>0&&data.map((row, index) => (
            <tr key={index}>
              <td style={tdStyle}>{row.date}</td>
              <td style={tdStyle}>{row.description}</td>
              <td style={tdStyle}><i className='fa fa-inr'></i> {row.debit}</td>
              <td style={tdStyle}><i className='fa fa-inr'></i> {row.credit}</td>
              <td style={tdStyle}><i className='fa fa-inr'></i> {row.balance}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
      </div>
    </div>
    </Layout>
   
  );
};

export default LedgerTable;
